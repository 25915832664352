<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                <v-btn-toggle>
                  <v-btn color="primary" @click="dispatchMonitoring()">
                    DISPATCH MONITORING
                  </v-btn>
                  <v-btn color="primary" @click="activeMonitoring()">
                    ACTIVE MONITORING
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid class="py-0">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2"></v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="font-size: 14px; margin-top: 14px; display: inline-block;"
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                        :items="page"
                        class="grey--text mx-2"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="font-size: 14px; margin-top: 14px; display: inline-block;"
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                          :items="page"
                          class="grey--text mx-2"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center;">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block;"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>

        <v-data-table
          dense
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody v-for="(item, index) in items" :key="index">
              <tr>
                <td class="text-center">
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td class="text-uppercase">
                  {{
                    item.company
                      ? item.company.name
                      : !item.company
                      ? "-----"
                      : null
                  }}
                </td>
                <td class="text-uppercase">
                  {{
                    item.subCompany
                      ? item.subCompany.name
                      : !item.subCompany
                      ? "-----"
                      : null
                  }}
                </td>
                <td class="text-uppercase">
                  {{ item.ticket_no }}
                </td>
                <td class="text-uppercase">
                  {{ item.vehicle ? item.vehicle.plate_no : "--" }}
                </td>
                <td class="text-center">
                  {{ item.dispatch_date }}
                </td>
                <td class="text-uppercase">
                  {{
                    item.monitor
                      ? item.monitor.first_name +
                        " " +
                        item.monitor.middle_name +
                        " " +
                        item.monitor.last_name
                      : "--"
                  }}
                </td>
                <td class="text-uppercase">
                  {{ item.route ? item.route.name : "--" }}
                </td>
                <td
                  class="text-uppercase viewCurrentLocation"
                  @click="clickOrigin(item)"
                >
                  {{ item.origin ? item.origin.name : "--" }}
                </td>
                <td
                  class="text-uppercase viewCurrentLocation"
                  @click="clickDestination(item)"
                >
                  {{ item.destination ? item.destination.name : "--" }}
                </td>
                <td class="text-center">{{ item.edd }}</td>
                <td class="text-center">{{ item.etd }}</td>
                <td class="text-center">{{ item.eda }}</td>
                <td class="text-center">{{ item.eta }}</td>
                <td
                  class="text-center"
                  :style="
                    item.status == 3
                      ? 'color: #F44336;'
                      : item.status == 2
                      ? 'color: #E91E63;'
                      : item.status == 0
                      ? 'color: #4CAF50;'
                      : '--'
                  "
                >
                  {{
                    item.status == 3
                      ? "CLOSED"
                      : item.status == 2
                      ? "CANCELLED"
                      : item.status == 0
                      ? "ACTIVE"
                      : "--"
                  }}
                </td>
                <!-- <td class="text-no-wrap text-center">
                  <v-tooltip v-if="item.status == 0" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="teal"
                        icon
                        @click="openEditDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <excel-button @click="downloadExcel(item)"></excel-button>
                  <cancel-dispatch-button
                    v-if="item.status == 0"
                    @click="openCancelDispatch(item)"
                  ></cancel-dispatch-button>
                  <close-dispatch-button
                    v-if="item.status == 0"
                    @click="openCloseDispatch(item)"
                  ></close-dispatch-button>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        v-on="on"
                        @click="openDispatchAlarms(item)"
                      >
                        <v-icon>mdi-file-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>Dispatch Alarms</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="amber"
                        icon
                        v-on="on"
                        @click="openIncidentReport(item)"
                      >
                        <v-icon>mdi-alert</v-icon>
                      </v-btn>
                    </template>
                    <span>Incident Report</span>
                  </v-tooltip>
                  <v-tooltip v-if="item.status == 0" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="teal"
                        icon
                        v-on="on"
                        @click="openEditRoute(item)"
                      >
                        <v-icon>mdi-routes</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Route</span>
                  </v-tooltip>
                </td> -->
              </tr>
              <tr>
                <td style="padding: 15px 15px;" colspan="2" rowspan="4">
                  <span
                    class="viewCurrentLocation"
                    @click="viewCurrentLocation(item)"
                  >
                    {{
                      item.vehicle_current_data
                        ? item.vehicle_current_data.lat +
                          "," +
                          item.vehicle_current_data.lng
                        : "--"
                    }}</span
                  ><br />
                  <span class="font-weight-bold">Time:</span>
                  {{
                    item.vehicle_current_data
                      ? item.vehicle_current_data.datetimestamp
                      : "--"
                  }}<br />
                  <span class="font-weight-bold">Status:</span>
                  {{
                    item.vehicle_current_data
                      ? item.vehicle_current_data.status
                      : "--"
                  }}
                </td>
                <td
                  style="padding: 15px 15px; border-right: #dddddd 1px solid;"
                  colspan="2"
                  rowspan="4"
                >
                  <span class="font-weight-bold">KM Away:</span>
                  {{
                    item.vehicle_current_data
                      ? item.vehicle_current_data.km_away
                      : "--"
                  }}<br />
                  <span class="font-weight-bold">KM Run:</span>
                  {{
                    item.vehicle_current_data
                      ? item.vehicle_current_data.km_run
                      : "--"
                  }}<br />
                  <span class="font-weight-bold">Alarm:</span>
                  <span style="color: red;">{{
                    item.vehicle_current_data
                      ? item.vehicle_current_data.alarm
                      : "--"
                  }}</span>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
                <td style="border-bottom: #dddddd 1px solid;"></td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the View Current Location Dialog ------>

    <mini-dialog
      :dialog="viewCurrentLocationDialog"
      :max-width="'1000px'"
      :cardActions="false"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text"
          >View Current Location</span
        >
        <v-spacer></v-spacer>
        <v-btn dark icon @click="closeViewCurrentLocationDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 500px;">
        <official-map ref="map"></official-map>
      </v-card-text>
    </mini-dialog>

    <!---- Here is the View Origin Dialog ------>

    <mini-dialog
      :dialog="viewOriginDialog"
      :max-width="'1000px'"
      :cardActions="false"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text">View Origin</span>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="closeViewOriginDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 500px;">
        <official-map ref="outletMap"></official-map>
      </v-card-text>
    </mini-dialog>

    <!---- Here is the View Destination Dialog ------>

    <mini-dialog
      :dialog="viewDestinationDialog"
      :max-width="'1000px'"
      :cardActions="false"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text"
          >View Destination</span
        >
        <v-spacer></v-spacer>
        <v-btn dark icon @click="closeViewDestinationDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 500px;">
        <official-map ref="outletMap"></official-map>
      </v-card-text>
    </mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/CompanyServices";
import XLSX from "xlsx";

export default {
  data: () => {
    return {
      title: "Active Monitoring",
      headers: [
        // {
        //   text: "#",
        //   sortable: false,
        //   align: "center",
        // },
        // { text: "Appointment Date", sortable: false, align: "center" },
        // {
        //   text: "Dispatch Ticket No",
        //   sortable: false,
        // },
        // { text: "Plate No", sortable: false },
        // { text: "Outlet", sortable: false },
        // { text: "Location", sortable: false },
        // // { text: "EDD/EDA", sortable: false, align: "center" },
        // // { text: "ETD/ETA", sortable: false, align: "center" },
        // { text: "EDA", sortable: false, align: "center" },
        // { text: "ETA", sortable: false, align: "center" },
        // { text: "Outlet", sortable: false },
        // { text: "Location", sortable: false },
        // { text: "ADA", sortable: false, align: "center" },
        // { text: "ATA", sortable: false, align: "center" },
        // { text: "ADD", sortable: false, align: "center" },
        // { text: "ATD", sortable: false, align: "center" },
        // // { text: "KM Run", sortable: false },

        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Company", sortable: false },
        { text: "Sub Company", sortable: false },
        {
          text: "Dispatch No",
          sortable: false,
        },
        { text: "Plate No", sortable: false },
        { text: "Dispatch Date", sortable: false, align: "center" },
        { text: "Monitored By" },
        { text: "Route", sortable: false },
        { text: "Origin", sortable: false },
        { text: "Destination", sortable: false },
        { text: "EDD", sortable: false, align: "center" },
        { text: "ETD", sortable: false, align: "center" },
        { text: "EDA", sortable: false, align: "center" },
        { text: "ETA", sortable: false, align: "center" },
        { text: "Status", sortable: false, align: "center" },
        // { text: "Actions", sortable: false, align: "center" },
      ],
      items: [],
      data: {},
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
      },
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      viewOriginDialog: false,
      viewDestinationDialog: false,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      viewCurrentLocationDialog: false,
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  methods: {
    async getData() {
      this.$store.dispatch("startLoading");

      this.queryParams.company_id = this.$store.state.company.id;

      try {
        let response = (
          await Services.getTrackingActiveMonitoringDispatches(this.queryParams)
        ).data;

        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }

        this.$store.dispatch("dispatchHasItems", true);
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    async initMap() {
      const boundsPolygon = [];
      const polygonsCoordinate = [];
      const plotRoute = [];

      await this.getData();

      await setTimeout(async () => {
        await this.$refs.map.initializeMap();

        if (this.items.length !== 0) {
          await Promise.all(
            this.items.map(async (item) => {
              const data = await this.setDispatch(item);

              const stopsArray = await Promise.all(
                data.stops_data.map(async (item) => {
                  return {
                    id: item.id,
                    coordinates: item.coordinates,
                    content: `Name: ${item.stop_name.toUpperCase()}`,
                  };
                })
              );

              stopsArray.forEach(async (item) => {
                item.coordinates.forEach((datum) => {
                  boundsPolygon.push({
                    lat: datum.lat,
                    lng: datum.lng,
                  });
                });

                polygonsCoordinate.push({
                  coordinates: item.coordinates,
                  content: item.content,
                  id: item.id,
                });

                plotRoute.push({
                  coordinates: item.coordinates,
                });

                await this.$refs.map.plotStops(item.coordinates);
              });

              const vehiclesArr = await Promise.all(
                data.vehicle_data.map((vehicle) => {
                  boundsPolygon.push({
                    lat: vehicle.lat,
                    lng: vehicle.lng,
                  });

                  return {
                    id: vehicle.id,
                    plate_no: vehicle.plate_no,
                    coordinates: {
                      lat: vehicle.lat,
                      lng: vehicle.lng,
                    },
                    content: `Plate No: ${vehicle.plate_no} <br> Status: ${vehicle.location}`,
                    // image_url: require("../../assets/marker_icons/bus.png")
                  };
                })
              );

              await this.$refs.map.insertMultipleMarker(vehiclesArr);
              await this.$refs.map.markerCluster();

              await this.$refs.map.boundsMarker(polygonsCoordinate);
              if (boundsPolygon.length !== 0) {
                await this.$refs.map.boundsOfAllMarkers(boundsPolygon);
              }

              // await this.$refs.map.makeRoute();
              await this.$refs.map.plottingRoute(plotRoute);
            })
          );
        }
      }, 1);
    },
    async viewCurrentLocation(data) {
      this.viewCurrentLocationDialog = true;

      const boundsVehicles = [];
      const vehiclesCoordinate = [];

      await setTimeout(async () => {
        this.$refs.map.initializeMap();

        let contentString = ``;

        contentString = `<b>Plate No.:</b> ${data.vehicle.plate_no.toUpperCase()}<br>
        <b>Location:</b> ${data.current_status.location.toUpperCase()}<br>`;

        boundsVehicles.push({
          lat: parseFloat(data.current_status.lat),
          lng: parseFloat(data.current_status.lng),
        });

        vehiclesCoordinate.push({
          coordinates: {
            lat: parseFloat(data.current_status.lat),
            lng: parseFloat(data.current_status.lng),
          },
          content: contentString,
          id: data.id,
          image_url: require("../../assets/marker_icons/bus.png"),
        });

        await this.$refs.map.insertMultipleMarker(vehiclesCoordinate);
        if (boundsVehicles.length !== 0) {
          await this.$refs.map.boundsOfAllMarkers(boundsVehicles);
        }
      }, 1);
    },
    closeViewCurrentLocationDialog() {
      this.viewCurrentLocationDialog = false;
    },
    async clickOrigin(item) {
      this.viewOriginDialog = true;

      const boundsPolygon = [];
      const polygonsCoordinate = [];

      await setTimeout(async () => {
        this.$refs.outletMap.initializeMap();

        let contentString = ``;

        contentString = `<b>Origin:</b> ${item.origin.name.toUpperCase()}<br>
        <b>Address:</b> ${item.origin.address.toUpperCase()}`;

        item.origin.coordinates.forEach((datum) => {
          boundsPolygon.push({
            lat: datum.lat,
            lng: datum.lng,
          });
        });

        polygonsCoordinate.push({
          coordinates: item.origin.coordinates,
          content: contentString,
          id: item.id,
          image_url: require("../../assets/outlet-markers/outlet_geozone.svg"),
          in_dashboard: true,
        });

        const color = "#008B0D";

        await this.$refs.outletMap.plotPolygons(item.origin.coordinates, color);

        await this.$refs.outletMap.boundsMarkerOutlet(polygonsCoordinate);
        if (boundsPolygon.length !== 0) {
          await this.$refs.outletMap.boundsOfAllMarkers(boundsPolygon);
        }
      }, 1);
    },
    closeViewOriginDialog() {
      this.viewOriginDialog = false;
    },
    async clickDestination(item) {
      this.viewDestinationDialog = true;

      const boundsPolygon = [];
      const polygonsCoordinate = [];

      await setTimeout(async () => {
        this.$refs.outletMap.initializeMap();

        let contentString = ``;

        contentString = `<b>Destination:</b> ${item.destination.name.toUpperCase()}<br>
        <b>Address:</b> ${item.destination.address.toUpperCase()}`;

        item.destination.coordinates.forEach((datum) => {
          boundsPolygon.push({
            lat: datum.lat,
            lng: datum.lng,
          });
        });

        polygonsCoordinate.push({
          coordinates: item.destination.coordinates,
          content: contentString,
          id: item.id,
          image_url: require("../../assets/outlet-markers/outlet_geozone.svg"),
          in_dashboard: true,
        });

        const color = "#008B0D";

        await this.$refs.outletMap.plotPolygons(
          item.destination.coordinates,
          color
        );

        await this.$refs.outletMap.boundsMarkerOutlet(polygonsCoordinate);
        if (boundsPolygon.length !== 0) {
          await this.$refs.outletMap.boundsOfAllMarkers(boundsPolygon);
        }
      }, 1);
    },
    closeViewDestinationDialog() {
      this.viewDestinationDialog = false;
    },
    dispatchMonitoring() {
      this.$router.push({
        path: "/c/outlet-monitoring",
      });
    },
    activeMonitoring() {
      this.$router.push({
        path: "/c/active-monitoring",
      });
    },
    async downloadExcel(data) {
      let dataToDownload = [];
      let header = [];

      for (let i = 0; i < this.headers.length; i++) {
        header.push(this.headers[i].text);
      }

      header.splice(0, 1);

      header.pop();

      dataToDownload.push(header);

      for (let e = 0; e < data.outlets.length; e++) {
        let theItem = [];

        if (e === 0) {
          theItem.push(data.ticket_no);
          theItem.push(data.vehicle.plate_no.toUpperCase());

          if (
            "outlet_name_1" &&
            "outlet_address_1" &&
            "eda" &&
            "eta" in data.outlets[e]
          ) {
            theItem.push(
              data.outlets[e].outlet_name_1.toUpperCase()
                ? data.outlets[e].outlet_name_1.toUpperCase()
                : ""
            );
            theItem.push(
              data.outlets[e].outlet_address_1
                ? data.outlets[e].outlet_address_1.toUpperCase()
                : ""
            );
            theItem.push(data.outlets[e].eda ? data.outlets[e].eda : "");
            theItem.push(data.outlets[e].eta ? data.outlets[e].eta : "");
          } else {
            theItem.push("");
            theItem.push("");
            theItem.push("");
            theItem.push("");
          }

          theItem.push(
            data.outlets[e].outlet_name_2
              ? data.outlets[e].outlet_name_2.toUpperCase()
              : ""
          );
          theItem.push(
            data.outlets[e].outlet_address_2
              ? data.outlets[e].outlet_address_2.toUpperCase()
              : ""
          );
          theItem.push(data.outlets[e].ada ? data.outlets[e].ada : "");
          theItem.push(data.outlets[e].ata ? data.outlets[e].ata : "");
          theItem.push(data.outlets[e].add ? data.outlets[e].add : "");
          theItem.push(data.outlets[e].atd ? data.outlets[e].atd : "");
          theItem.push(data.outlets[e].km_run ? data.outlets[e].km_run : "");
        } else {
          theItem.push("");
          theItem.push("");

          if (
            "outlet_name_1" &&
            "outlet_address_1" &&
            "eda" &&
            "eta" in data.outlets[e]
          ) {
            theItem.push(
              data.outlets[e].outlet_name_1.toUpperCase()
                ? data.outlets[e].outlet_name_1.toUpperCase()
                : ""
            );
            theItem.push(
              data.outlets[e].outlet_address_1
                ? data.outlets[e].outlet_address_1.toUpperCase()
                : ""
            );
            theItem.push(data.outlets[e].eda ? data.outlets[e].eda : "");
            theItem.push(data.outlets[e].eta ? data.outlets[e].eta : "");
          } else {
            theItem.push("");
            theItem.push("");
            theItem.push("");
            theItem.push("");
          }

          theItem.push(
            data.outlets[e].outlet_name_2
              ? data.outlets[e].outlet_name_2.toUpperCase()
              : ""
          );
          theItem.push(
            data.outlets[e].outlet_address_2
              ? data.outlets[e].outlet_address_2.toUpperCase()
              : ""
          );
          theItem.push(data.outlets[e].ada ? data.outlets[e].ada : "");
          theItem.push(data.outlets[e].ata ? data.outlets[e].ata : "");
          theItem.push(data.outlets[e].add ? data.outlets[e].add : "");
          theItem.push(data.outlets[e].atd ? data.outlets[e].atd : "");
          theItem.push(data.outlets[e].km_run ? data.outlets[e].km_run : "");
        }

        dataToDownload.push(theItem);
      }

      // don't delete any codes after this comment got it?!

      let wscols = [
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
      ];

      let wsrows = [];

      let ws = XLSX.utils.aoa_to_sheet(dataToDownload, { cellDates: true });
      let wb = XLSX.utils.book_new();

      let fmt = "#,##0";

      let range = XLSX.utils.decode_range(ws["!ref"]);
      for (let e = range.s.r + 1; e <= range.e.r; ++e) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          let ref = XLSX.utils.encode_cell({ r: e, c: C });
          if (!ws[ref]) continue;
          if (ws[ref].t !== "n") continue;
          ws[ref].z = fmt;
        }
      }

      XLSX.utils.book_append_sheet(wb, ws);

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;

      wb.Props = {
        Title: this.title,
        Subject: this.title,
        Author: "Developer of Journeytech",
        Company: "Journeytech",
        Category: "Reports",
      };

      // ws["!protect"] = {
      //   password: "Journeytech@1",
      //   formatRows: false,
      //   formatColumns: false,
      //   objects: true,
      //   scenarios: true
      // };

      if (!wb.Workbook) wb.Workbook = { Sheets: [], WBProps: {} };
      if (!wb.Workbook.WBProps) wb.Workbook.WBProps = {};
      wb.Workbook.WBProps.filterPrivacy = true;

      let filenames = [
        [this.title + ".xlsx"],
        [this.title + ".xlsm"],
        [this.title + ".xlsb"],
        [this.title + ".xlam"],
        [this.title + ".biff8.xls", { bookType: "xls" }],
        [this.title + ".biff5.xls", { bookType: "biff5" }],
        [this.title + ".biff2.xls", { bookType: "biff2" }],
        [this.title + ".xml.xls", { bookType: "xlml" }],
        [this.title + ".xla"],
        [this.title + ".ods"],
        [this.title + ".fods"],
        [this.title + ".csv"],
        [this.title + ".txt"],
        [this.title + ".slk"],
        [this.title + ".eth"],
        [this.title + ".htm"],
        [this.title + ".dif"],
        [this.title + ".dbf", { sheet: "Hidden" }],
        [this.title + ".rtf"],
        [this.title + ".prn"],
      ];
      let OUT = ["base64", "binary", "string", "array"];
      if (typeof Buffer !== "undefined") OUT.push("buffer");
      filenames.forEach(function(r) {
        XLSX.writeFile(wb, r[0], r[1]);
        if (typeof process !== "undefined") XLSX.readFile(r[0]);

        let ext = (r[1] && r[1].bookType) || r[0].split(".")[1];
        ext = { htm: "html" }[ext] || ext;
        OUT.forEach(function(type) {
          if (
            type === "string" &&
            [
              "xlsx",
              "xlsm",
              "xlsb",
              "xlam",
              "biff8",
              "biff5",
              "biff2",
              "xla",
              "ods",
              "dbf",
            ].indexOf(ext) > -1
          )
            return;
          if (
            type === "array" &&
            ["xlsx", "xlsm", "xlsb", "xlam", "ods"].indexOf(ext) > -1 &&
            typeof Uint8Array === "undefined"
          )
            return;
          let datout = XLSX.write(wb, {
            type: type,
            bookType: ext,
            sheet: (r[1] && r[1].sheet) || null,
          });
          XLSX.read(datout, { type: type });
          if (type === "array") console.log(ext, datout);
        });
      });
    },
    cutHeadings(data) {
      let text = null;
      text = data.length < 5 ? data : data.slice(0, 40) + "...";
      return text;
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style scoped>
input[type="time"] {
  /* border: 5px solid #b71c1c; */
  color: #000000;
  font-size: 14px;
  font-family: helvetica;
  width: 100%;
}

input[type="time"]:focus {
  outline: none;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */
input[type="time"]::-webkit-datetime-edit-text {
  padding: 19px 4px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type="time"]::-webkit-datetime-edit-hour-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 25px;
}

/* Minute */
input[type="time"]::-webkit-datetime-edit-minute-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 25px;
}

/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  background-color: #b71c1c;
  border-radius: 15%;
  color: #fff;
  padding: 19px 25px;
}

/* 'X' button for resetting/clearing time */
input[type="time"]::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}

.viewCurrentLocation {
  cursor: pointer;
  color: red;
}

.viewCurrentLocation:hover {
  text-decoration: underline;
}

tbody tr:hover {
  background-color: transparent !important;
}

.my-border td:nth-child(1) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(2) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(3) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(4) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(5) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(6) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(7) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(8) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(9) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(10) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(11) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(12) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(13) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(14) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(15) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(16) {
  border-top: 1px solid #dddddd;
}

.anUnderline {
  text-decoration: underline;
  cursor: pointer;
}

.txt:hover {
  text-decoration: underline;
}

ol {
  list-style: none;
}

ol li:before {
  content: "\00BB \0020";
}

.gradientColor {
  background-color: #990000;
  background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
}

.dotted-underline {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.scrolling-wrapper-dispatch {
  overflow-x: hidden;
  height: 300px;
  overflow-y: scroll;
  white-space: nowrap;
}

.scrolling-wrapper-finalize {
  overflow-x: hidden;
  height: 200px;
  overflow-y: scroll;
  white-space: nowrap;
}
</style>

<!-- 
<v-data-table
          dense
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody v-for="(item, index) in items" :key="index">
              <tr v-for="(obj, i) in item.outlets" :key="i">
                <td
                  class="text-center"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td
                  class="text-center text-no-wrap"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ $dateFormat(item.appointment_date, "YYYY-MM-DD") }}
                </td>
                <td
                  class="text-no-wrap text-uppercase"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ item.ticket_no }}
                </td>
                <td
                  class="text-no-wrap text-uppercase"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ item.vehicle === null ? "" : item.vehicle.plate_no }}
                </td>
                <td
                  :style="
                    i === 1
                      ? 'border-bottom: none; vertical-align:top; padding-top: 25px;'
                      : 'display: none;'
                  "
                  colspan="4"
                  :rowspan="item.outlets.length"
                >
                  <b>Last Seen:</b>
                  &nbsp;
                  {{
                    item.current_status
                      ? item.current_status.datetimestamp
                      : "--"
                  }}
                  <br />
                  <b>Location:</b>&nbsp;
                  <span
                    class="viewCurrentLocation"
                    @click="viewCurrentLocation(item)"
                    >{{
                      item.current_status ? item.current_status.location : "--"
                    }}</span
                  >
                  <br />
                  <b>KM Run:</b>
                  {{ item.current_status ? 0 + "km" : "" }} 
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_name_1 }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_address_1 ? obj.outlet_address_1 : "--" }}
                </td>
                <td class="text-center text-no-wrap">
                  {{ obj.eda ? obj.eda : "--" }}
                </td>
                <td class="text-center text-no-wrap">
                  {{ obj.eta ? obj.eta : "--" }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_name_2 }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_address_2 }}
                </td>
                <td class="text-center text-no-wrap">{{ obj.ada }}</td>
                <td class="text-center text-no-wrap">{{ obj.ata }}</td>
                <td class="text-center text-no-wrap">{{ obj.add }}</td>
                <td class="text-center text-no-wrap">{{ obj.atd }}</td>
                 <td class="text-no-wrap text-uppercase">{{ obj.km_run }}</td> 
              </tr>
            </tbody>
          </template>
        </v-data-table> -->
